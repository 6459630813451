var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',{staticClass:"table-custom",attrs:{"body-classes":"p-0 d-flex flex-column"}},[_c('AffiliateTransactionList',{attrs:{"fields":[
      {
        key: 'id',
        stickyColumn: true,
        label: _vm.$t('general.id'),
        formatter: function (val) { return '#' + val.toString(); },
        sortable: true,
      },
      {
        key: 'affiliate_name',
        label: _vm.$tc('affiliate.referrer_username', 1),
      },
      {
        key: 'withdraw_amount',
        label: _vm.$t('affiliate.withdraw_amount'),
        sortable: true,
        formatter: function (v) { return _vm.formatWithGBP(v); },
      },
      {
        key: 'commission_before',
        label: _vm.$t('affiliate.balance_before'),
        sortable: true,
        formatter: function (v) { return _vm.formatWithGBP(v); },
      },
      {
        key: 'commission_after',
        label: _vm.$t('affiliate.balance_after'),
        sortable: true,
        formatter: function (v) { return _vm.formatWithGBP(v); },
      },
      {
        key: 'comment',
        label: _vm.$t('affiliate.comment'),
      },
      {
        key: 'status',
        label: _vm.$t('general.status'),
        sortable: true,
      },
      {
        key: 'actions',
        label: _vm.$tc('general.action', 1),
        class: 'text-center px-2',
      },
      {
        key: 'created_at',
        label: _vm.$t('general.created_at'),
        sortable: true,
        formatter: function (v) { return _vm.formatLocalDateTime(v); },
      } ]},scopedSlots:_vm._u([{key:"affiliate_name",fn:function(ref){
    var user = ref.user;
return [_c('span',{staticClass:"action",on:{"click":function($event){return _vm.openUserDetails(user.affiliate)}}},[_vm._v(_vm._s(user.affiliate_name))])]}}])}),_c('UserDetailModal',{attrs:{"detailTarget":_vm.detailTarget,"hideModal":_vm.hideModal,"user":_vm.detailTarget,"isAffiliate":true}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }