<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <AffiliateTransactionList
      :fields="[
        {
          key: 'id',
          stickyColumn: true,
          label: $t('general.id'),
          formatter: val => '#' + val.toString(),
          sortable: true,
        },
        {
          key: 'affiliate_name',
          label: $tc('affiliate.referrer_username', 1),
        },
        {
          key: 'withdraw_amount',
          label: $t('affiliate.withdraw_amount'),
          sortable: true,
          formatter: v => formatWithGBP(v),
        },
        {
          key: 'commission_before',
          label: $t('affiliate.balance_before'),
          sortable: true,
          formatter: v => formatWithGBP(v),
        },
        {
          key: 'commission_after',
          label: $t('affiliate.balance_after'),
          sortable: true,
          formatter: v => formatWithGBP(v),
        },
        {
          key: 'comment',
          label: $t('affiliate.comment'),
        },
        {
          key: 'status',
          label: $t('general.status'),
          sortable: true,
        },
        {
          key: 'actions',
          label: $tc('general.action', 1),
          class: 'text-center px-2',
        },
        {
          key: 'created_at',
          label: $t('general.created_at'),
          sortable: true,
          formatter: v => formatLocalDateTime(v),
        },
      ]"
    >
      <template #affiliate_name="{ user }">
        <span class="action" @click="openUserDetails(user.affiliate)">{{ user.affiliate_name }}</span>
      </template>
    </AffiliateTransactionList>

    <UserDetailModal :detailTarget="detailTarget" :hideModal="hideModal" :user="detailTarget" :isAffiliate="true" />
  </card>
</template>
<script>
import UserDetailModal from '@/components/Users/UserDetailModal.vue'
import AffiliateTransactionList from '@/components/Affiliates/AffiliateTransactionList.vue'
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import { formatWithGBP } from '@/utils/numberUtils'

export default {
  components: {
    UserDetailModal,
    AffiliateTransactionList,
  },
  data() {
    return {
      detailTarget: null,
      currency: 'GBP',
    }
  },
  methods: {
    openUserDetails(obj) {
      this.detailTarget = obj
      this.$bvModal.show('user-details')
    },
    hideModal() {
      this.detailTarget = null
    },
    formatLocalDateTime,
    formatWithGBP,
  },
}
</script>
